export const colors = {
  success300: '#5da862',
  success200: '#d6e9d7',
  danger300: '#be0000',
  warning300: '#f1b000',
  interaction300: '#0094ca',
  interaction200: '#21c5fd',
  interaction100: '#83dfff',
  text400: '#020303',
  text300: '#263238',
  text200: '#37474f',
  text100: '#455a64',
  neutral500: '#546e7a',
  neutral400: '#607d8b',
  neutral300: '#78909c',
  neutral200: '#90a4ae',
  neutral100: '#b0bec5',
  grey200: '#eceff1',
  grey300: '#cfd8dc',
  white: '#ffffff',
  black: '#000000',
};
