import styled from '@emotion/styled';

const RightContainer = styled.div({
  marginLeft: '32px',
  flexGrow: 1,
  flexBasis: 0,
  position: 'relative',
});

export default RightContainer;
