import cns from 'classnames';
import _ from 'lodash';
import {
  InputHTMLAttributes,
  useState,
  FocusEventHandler,
  FocusEvent,
  ReactNode,
  FC,
  ForwardedRef,
  forwardRef,
} from 'react';
import { Input as MapInput, Label, FormControl } from '@alexpireddu/map-ui';

export interface InputIconProps {
  position: 'left' | 'right';
  content: ReactNode;
}

export interface InputProps
  // eslint-disable-next-line no-undef
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> {
  className?: string;
  fullwidth?: boolean;
  icon?: ReactNode | InputIconProps;
  label?: string;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  state?: 'success' | 'error';
  type?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  ref?: ForwardedRef<HTMLInputElement>;
}

const Input: FC<InputProps> = forwardRef(
  (
    {
      className,
      fullwidth = false,
      icon,
      label,
      onBlur,
      onFocus,
      state,
      type = 'text',
      ...rest
    }: InputProps,
    ref
  ) => {
    const [isFocused, setFocus] = useState(false);

    const handleOnBlur = (evt: FocusEvent<HTMLInputElement>) => {
      setFocus(false);

      if (!_.isUndefined(onBlur)) {
        onBlur(evt);
      }
    };
    const handleOnFocus = (evt: FocusEvent<HTMLInputElement>) => {
      setFocus(true);

      if (!_.isUndefined(onFocus)) {
        onFocus(evt);
      }
    };

    let iconPosition = 'left';
    let iconContent = icon;
    if (icon instanceof Object && 'position' in icon) {
      iconPosition = icon.position;
      iconContent = icon.content;
    }

    const classes = cns(
      'p__inputContainer',
      {
        'p__inputContainer--focused': isFocused,
        'p__inputContainer--fullwidth': fullwidth,
      },
      {
        'p__inputContainer--success': state === 'success',
        'p__inputContainer--error': state === 'error',
      },
      {
        'p__inputContainer--icon': !_.isUndefined(icon),
        'p__inputContainer--icon-left':
          !_.isUndefined(icon) && iconPosition === 'left',
        'p__inputContainer--icon-right':
          !_.isUndefined(icon) && iconPosition === 'right',
      },
      className
    );

    return (
      <FormControl>
        {label && <Label>{label}</Label>}

        <div className={classes}>
          {!_.isUndefined(icon) && iconPosition === 'left' && (
            <div className="p__inputIcon">{iconContent}</div>
          )}

          <MapInput
            onBlur={handleOnBlur}
            onFocus={handleOnFocus}
            className={cns('p__input')}
            type={type}
            ref={ref}
            {...rest}
          />

          {!_.isUndefined(icon) && iconPosition === 'right' && (
            <div className="p__inputIcon">{iconContent}</div>
          )}
        </div>
      </FormControl>
    );
  }
);

Input.displayName = 'Input';

export default Input;
