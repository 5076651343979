import cns from 'classnames';
import _ from 'lodash';
import { FC } from 'react';
import { LinkProps, Link as RouterLink } from 'react-router-dom';

const Link: FC<
  LinkProps & {
    href?: string;
  }
> = ({ children, className, to, href, ...others }) => {
  const classes = cns('p__link', className);

  if (!_.isUndefined(to)) {
    return (
      <RouterLink to={to} className={classes} {...others}>
        {children}
      </RouterLink>
    );
  }

  if (!_.isUndefined(href)) {
    return (
      <a href={href} className={classes} {...others}>
        {children}
      </a>
    );
  }

  return (
    <span className={classes} {...others}>
      {children}
    </span>
  );
};

export default Link;
