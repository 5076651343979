import { Box, Button, Flex } from '@alexpireddu/map-ui';
import styled from '@emotion/styled';
import cns from 'classnames';
import _ from 'lodash';
import { FC } from 'react';
import icon from './404_ICON.svg';

export interface DefaultErrorPageProps {
  className?: string;
  details?: string;
}

const ErrorPageWrapper = styled.div({
  display: 'inline-block',
  margin: '0 auto',
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '16px',
});

const ErrorPageImage = styled.img({
  fill: '#4F5666',
  color: '#4F5666',
  width: '200px',
  height: '200px',
  transform: 'translateX(50%)',
});

const DefaultErrorPage: FC<DefaultErrorPageProps> = ({
  className,
  details,
}) => {
  const reloadPage = () => {
    window.location.reload();
  };

  return (
    <div className={cns('pa__errorPageContainer', className)}>
      <ErrorPageWrapper>
        <ErrorPageImage src={icon} />
        <div style={{ textAlign: 'center', marginTop: '32px' }}>
          <h2>Spiacenti, si è verificato un errore</h2>
        </div>
        <Flex mt="32px" justifyContent="center">
          <Box>
            <Button variant="secondary" onClick={reloadPage}>
              Ricarica la pagina
            </Button>
          </Box>
        </Flex>
        <div style={{ marginTop: '16px' }}>
          {!_.isNil(details) && <details>{details}</details>}
        </div>
      </ErrorPageWrapper>
    </div>
  );
};

export default DefaultErrorPage;
