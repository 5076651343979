import { FC, Suspense, lazy } from 'react';
import { Helmet } from 'react-helmet';
import { Navigate, Route } from 'react-router-dom';
import { SentryRoutes } from './infrastructure/sentryConfiguration';
import { NotFoundErrorPage } from './components/ErrorPages';

const Accedi = lazy(() => import('./areas/Accedi'));
const RipristinaPassword = lazy(() => import('./areas/RipristinaPassword'));
const CambiaPassword = lazy(() => import('./areas/CambiaPassword'));

const MainRouting: FC = () => (
  <>
    <Helmet titleTemplate="Publista - %s" defaultTitle="Home" />

    <Suspense fallback={<span />}>
      <SentryRoutes>
        <Route path="/" element={<Navigate to="/accedi" />} />
        <Route path="/ripristina-password" element={<RipristinaPassword />} />
        <Route
          path="/cambia-password/:authToken"
          element={<CambiaPassword />}
        />
        <Route path="/accedi" element={<Accedi />} />
        <Route path="/accedi/redirect" element={<Accedi />} />
        <Route path="*" element={<NotFoundErrorPage />} />
      </SentryRoutes>
    </Suspense>
  </>
);

export default MainRouting;
