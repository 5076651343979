import { FC, ComponentProps } from 'react';
import { Label as MapLabel } from '@alexpireddu/map-ui';

export interface LabelProps extends ComponentProps<typeof MapLabel> {
  className?: string;
  state?: 'error' | 'success';
}

const Label: FC<LabelProps> = ({
  children,
  className,
  state,
  ...others
}: LabelProps) => (
  <MapLabel
    className={className}
    isInvalid={state === 'error'}
    isVerified={state === 'success'}
    {...others}
  >
    {children}
  </MapLabel>
);

export default Label;
