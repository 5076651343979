import {
  DEV_AUTH_CITYADMIN_REDIRECT_URI,
  DEV_AUTH_PARTNER_REDIRECT_URI,
  DEV_AUTH_SUPERADMIN_REDIRECT_URI,
  DEV_BACKEND_URI,
  PROD_AUTH_CITYADMIN_REDIRECT_URI,
  PROD_AUTH_PARTNER_REDIRECT_URI,
  PROD_AUTH_SUPERADMIN_REDIRECT_URI,
  PROD_BACKEND_URI,
  TEST_AUTH_CITYADMIN_REDIRECT_URI,
  TEST_AUTH_PARTNER_REDIRECT_URI,
  TEST_AUTH_SUPERADMIN_REDIRECT_URI,
  TEST_BACKEND_URI,
} from './constants';

export const isTestMode = (): boolean =>
  window.location.href.indexOf('qa.publista.it') !== -1;

export const isDevMode = (): boolean =>
  window.location.hostname === 'localhost';

export const isProdMode = (): boolean => !isTestMode() && !isDevMode();

export const getBackendBaseUri = () => {
  if (isDevMode()) {
    return DEV_BACKEND_URI;
  }

  if (isTestMode()) {
    return TEST_BACKEND_URI;
  }

  return PROD_BACKEND_URI;
};

export const getSuperAdminRedirectUri = () => {
  if (isDevMode()) {
    return DEV_AUTH_SUPERADMIN_REDIRECT_URI;
  }

  if (isTestMode()) {
    return TEST_AUTH_SUPERADMIN_REDIRECT_URI;
  }

  return PROD_AUTH_SUPERADMIN_REDIRECT_URI;
};

export const getCityAdminRedirectUri = () => {
  if (isDevMode()) {
    return DEV_AUTH_CITYADMIN_REDIRECT_URI;
  }

  if (isTestMode()) {
    return TEST_AUTH_CITYADMIN_REDIRECT_URI;
  }

  return PROD_AUTH_CITYADMIN_REDIRECT_URI;
};

export const getPartnerRedirectUri = () => {
  if (isDevMode()) {
    return DEV_AUTH_PARTNER_REDIRECT_URI;
  }

  if (isTestMode()) {
    return TEST_AUTH_PARTNER_REDIRECT_URI;
  }

  return PROD_AUTH_PARTNER_REDIRECT_URI;
};
