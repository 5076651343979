export const X_API_KEY = 'd9316efd-b3a5-40bf-89bd-847aca3aab16';

export const SENTRY_API_KEY =
  'https://65cd401b58724aa9afadd99758f34bd6@o749218.ingest.sentry.io/5888713';

export const DEV_BACKEND_URI = 'http://localhost:8080';

export const TEST_BACKEND_URI = 'https://api.qa.publista.it';

export const PROD_BACKEND_URI = 'https://api.io.publista.it';

export const DEV_AUTH_SUPERADMIN_REDIRECT_URI = 'http://localhost:3005';

export const DEV_AUTH_CITYADMIN_REDIRECT_URI = 'http://localhost:3001';

export const DEV_AUTH_PARTNER_REDIRECT_URI = 'http://localhost:3002';

export const TEST_AUTH_SUPERADMIN_REDIRECT_URI = 'https://super.qa.publista.it';

export const TEST_AUTH_CITYADMIN_REDIRECT_URI = 'https://comune.qa.publista.it';

export const TEST_AUTH_PARTNER_REDIRECT_URI = 'https://partner.qa.publista.it';

export const PROD_AUTH_SUPERADMIN_REDIRECT_URI = 'https://super.io.publista.it';

export const PROD_AUTH_CITYADMIN_REDIRECT_URI = 'https://comune.io.publista.it';

export const PROD_AUTH_PARTNER_REDIRECT_URI = 'https://partner.io.publista.it';

export const GENERIC_ERROR_MESSAGE =
  'Siamo spiacenti, si è verificato un errore.';
