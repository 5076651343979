import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cns from 'classnames';
import { FC } from 'react';

export interface FaIconProps {
  className?: string;
  icon: IconProp;
  pointer?: boolean;
}

const FaIcon: FC<FaIconProps> = ({
  className,
  icon,
  pointer,
  ...others
}: FaIconProps) => (
  <FontAwesomeIcon
    className={cns(
      'p__faIcon',
      {
        'p__faIcon--pointer': pointer,
      },
      className
    )}
    icon={icon}
    {...others}
  />
);

export default FaIcon;
