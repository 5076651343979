import styled from '@emotion/styled';

const Container = styled.div({
  position: 'absolute',
  top: '0',
  left: '0',
  bottom: '0',
  right: '0',
});

export default Container;
