import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faEye,
  faEyeSlash,
  faPhoneAlt,
} from '@fortawesome/free-solid-svg-icons';
import FaIcon from './FaIcon';

library.add(faEye, faEyeSlash, faPhoneAlt);

export default FaIcon;
