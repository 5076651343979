import { Card } from '@alexpireddu/map-ui';
import { CardProps } from '@alexpireddu/map-ui/dist/components/Card/Card';
import styled from '@emotion/styled';
import { FC } from 'react';

interface InternalCardProps extends Omit<CardProps, 'size'> {}

const InternalCard: FC<CenteredCardProps> = ({ windowSize, ...props }) => (
  <Card size={windowSize === 'full' ? 'xl' : 'md'} {...props} />
);

export interface CenteredCardProps extends InternalCardProps {
  windowSize?: 'full' | 'small';
}

const CenteredCard = styled(InternalCard)<CenteredCardProps>(
  ({ windowSize = 'full' }) => ({
    width: windowSize === 'full' ? '830px' : '380px',
    display: 'flex',
    position: 'absolute',
    top: '50%',
    left: '50%',
    msTransform: 'translateY(-50%) translateX(-50%)',
    transform: 'translateY(-50%) translateX(-50%)',
  })
);

export default CenteredCard;
