import { FC, ReactNode } from 'react';
import { Text } from '@alexpireddu/map-ui';

export interface HeadingProps {
  children: ReactNode;
  className?: string;
}
const Heading: FC<HeadingProps> = ({
  children,
  className,
  ...others
}: HeadingProps) => (
  <Text
    as="h2"
    fontSize={30}
    fontWeight={600}
    fontFamily="Titillium Web"
    fontStyle="normal"
    letterSpacing="normal"
    lineHeight="46px"
    className={className}
    {...others}
  >
    {children}
  </Text>
);

export default Heading;
