import styled from '@emotion/styled';
import { colors } from '../../theming/theme';

export interface LeftContainerProps {
  windowSize?: 'full' | 'small';
}

const LeftContainer = styled.div<LeftContainerProps>(
  ({ windowSize = 'full' }) => ({
    paddingRight: windowSize === 'full' ? '32px' : 0,
    flexGrow: 1,
    flexBasis: 0,
    borderRight: windowSize === 'full' ? 'solid 2px ' + colors.text100 : 'none',
  })
);

export default LeftContainer;
