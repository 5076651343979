import './theming/App.scss';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { isDevMode } from './infrastructure/environments';
import { MapUiProvider } from '@alexpireddu/map-ui';
import MainRouting from './MainRouting';
import { render } from 'react-dom';
import { initializeSentry } from './infrastructure/sentryConfiguration';
import { ErrorBoundary } from './components';

initializeSentry();

render(
  <MapUiProvider theme={{}}>
    <BrowserRouter>
      <ErrorBoundary>
        <MainRouting />
      </ErrorBoundary>
    </BrowserRouter>
  </MapUiProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (isDevMode()) {
  reportWebVitals(console.info);
}
