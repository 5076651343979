import styled from '@emotion/styled';

const SideImage = styled.img({
  width: '100%',
  position: 'absolute',
  top: '50%',
  msTransform: 'translateY(-50%)',
  transform: 'translateY(-50%)',
  maxHeight: '100%',
  objectFit: 'contain',
});

export default SideImage;
